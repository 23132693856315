/**
 * @flow
 */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../theme/Theme.context";
import { sendIcon, tutorialVector } from "../../assets";
import { Button } from "../../components/index";
import "./TutorialScreen.scss";

const TutorialScreen = (): React$MixedElement => {
  const navigate = useNavigate();

  const { theme, updateTheme } = useTheme();
  const { themeName } = useParams();

  useEffect(() => {
    if (theme !== themeName) {
      updateTheme(themeName);
    }
  }, []);

  const onClick = () => {
    navigate(`/${theme}/homeScreen`);
  };
  return (
    <div className="tutorial-screen">
      <div className="info-container">
        <div className="h3 text-color">Take a picture of</div>
        <div className="italian-bold">Your Wheel,</div>
        <div className="h3 text-color">facing the rim</div>
      </div>
      <div className="sample-snap-container">
        <img
          className="sample-snap"
          src={tutorialVector}
          alt="sample tyre snap"
        />
      </div>
      <div className="tutorial-message ">
        <div className="h3 text-color">The perfect picture is</div>
        <div className="italian-bold">CENTERED</div>
        <div className="h3 text-color">
          with little to no angle with the axis
        </div>
      </div>
      <Button
        buttonStyle="secondary-button lets-go-btn"
        onClick={onClick}
        icon={sendIcon}
        buttonText="Let's Go"
      />
    </div>
  );
};

export default TutorialScreen;
