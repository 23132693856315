import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../theme/Theme.context";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { OptionCard, Button } from "../../components";
import { carModel, rearTyreSelection, frontTyreSelection } from "../../assets";
import "./TyreOptionScreen.scss";
import { setTyreDimensionSelection } from "../../statemanagment/index";

const TyreOptionScreen = () => {
  const { carModelSelection } = useSelector(
    (state) => state.tyreSnapApiReducer,
    shallowEqual
  );
  const navigate = useNavigate();
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);
  const setOption = useCallback((option) => {
    setSelectedOption(option);
  }, []);

  useEffect(() => {
    if (Object.keys(carModelSelection).length === 0) {
      navigate(`/${theme}/homeScreen`, { replace: true });
    }
  }, [carModelSelection, theme, navigate]);

  const getTyreImage = () => {
    switch (selectedOption) {
      case "F":
        return frontTyreSelection;
      case "R":
        return rearTyreSelection;
      default:
        return carModel;
    }
  };
  const submitDetails = useCallback(() => {
    let details =
      selectedOption === "F"
        ? carModelSelection?.tyre_dimensions?.front
        : carModelSelection?.tyre_dimensions?.rear;
    dispatch(setTyreDimensionSelection(details));

    if (details.special_marking.runflat) {
      navigate(`/${theme}/runFlatScreen`);
    } else {
      navigate(`/${theme}/finalScreen`);
    }
  }, [selectedOption, carModelSelection, theme, dispatch, navigate]);

  return (
    <div className="tyreOption-screen">
      <div className="info-container">
        <div className="h3">Are you looking for front or rear tyres?</div>
      </div>
      <div className="sample-snap-container">
        <img
          className="sample-snap"
          src={getTyreImage()}
          alt="sample tyre snap"
        />
      </div>
      <div className="option-container">
        <OptionCard
          label={"FRONT TYRES"}
          setOption={setOption}
          checked={selectedOption === "F"}
          option={"F"}
        >
          <div className="text-color-secondary bold h3">
            <div>{carModelSelection?.tyre_dimensions?.front.tyresize}</div>
          </div>
        </OptionCard>
        <OptionCard
          label={"REAR TYRES"}
          setOption={setOption}
          checked={selectedOption === "R"}
          option={"R"}
        >
          <div className="text-color-secondary bold h3">
            <div>{carModelSelection?.tyre_dimensions?.rear.tyresize}</div>
          </div>
        </OptionCard>
      </div>
      <div className="button-div">
        <Button
          buttonStyle={
            selectedOption !== null ? "secondary-button" : "default-button"
          }
          onClick={submitDetails}
          buttonText="Confirm"
          disabledButton={selectedOption !== null ? false : true}
        />
      </div>
    </div>
  );
};

export default TyreOptionScreen;
