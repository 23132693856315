/**
 * @flow
 */
import * as React from "react";
import "./OptionCard.scss";

type props = {
  label: string,
  children: React.Node,
  setOption: Function,
  checked: boolean,
  option: string | number,
};

const OptionCard = ({
  label,
  children,
  setOption,
  checked,
  option,
}: props): React$MixedElement => {
  return (
    <div
      className={`option-card  ${
        checked ? "selected-background" : "default-background"
      }`}
      onClick={() => setOption(option)}
    >
      <div className="radio-container">
        <input
          name={`radio_${label}`}
          type="radio"
          checked={checked}
          readOnly
        ></input>
        <label for={`radio_${label}`} className="bold h3 radio-label">
          {label}
        </label>
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
};

export default OptionCard;
