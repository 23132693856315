/**
 * @flow
 */
import React from "react";
import "./ErrorScreen.scss";
import Button from "../Button/Button";

type props = {
  errorMessage: string,
  onRetry: Function,
};

const ErrorScreen = ({ errorMessage, onRetry }: props): React$MixedElement => {
  return (
    <div className="error-screen-container">
      <div className="content">
        <div className="h1">{errorMessage}</div>
        <Button
          buttonStyle="secondary-button"
          onClick={onRetry}
          buttonText="Retry"
        />
      </div>
    </div>
  );
};

export default ErrorScreen;
