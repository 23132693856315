/**
 * @flow
 */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "../../theme/Theme.context";
import { finalFlag } from "../../assets";
import "./FinalScreen.scss";

const FinalScreen = (): React$MixedElement => {
  const { theme, updateTheme } = useTheme();
  const { themeName } = useParams();

  useEffect(() => {
    if (theme !== themeName) {
      updateTheme(themeName);
    }
  }, []);

  return (
    <div className="result-screen">
      <div className="final-message">
        <img
          className="final-flag-snap"
          src={finalFlag}
          alt="final flag snap"
        />
        <div className="italian-bold">CONGRATULATIONS!</div>
        <div className="italian h3">
          <div>You will be redirected to our</div>
          <div>E-shop in just a moment.</div>
        </div>
      </div>
    </div>
  );
};

export default FinalScreen;
