import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  TutorialScreen,
  CameraScreen,
  ViewImageScreen,
  HomeScreen,
  ResultScreen,
  FinalScreen,
  TyreOptionScreen,
  RunFlatScreen,
} from "../screens/index";
import { THEMES } from "../theme/theme.constants";

const RouteConfig = () => {
  return (
    <Routes>
      <Route path=":themeName">
        <Route path="" element={<TutorialScreen />} />
        <Route path="cameraScreen" element={<CameraScreen />} />
        <Route path="viewImageScreen" element={<ViewImageScreen />} />
        <Route path="homeScreen" element={<HomeScreen />} />
        <Route path="resultScreen" element={<ResultScreen />} />
        <Route path="finalScreen" element={<FinalScreen />} />
        <Route path="tyreOptionScreen" element={<TyreOptionScreen />} />
        <Route path="runFlatScreen" element={<RunFlatScreen />} />
      </Route>
      <Route path="">
        <Route
          path="/"
          element={<Navigate to={`/${THEMES.DEFAULT}`} replace />}
        />
      </Route>
    </Routes>
  );
};

export default RouteConfig;
