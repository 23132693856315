import React, { useCallback, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeContext } from "./theme/Theme.context";
import { THEMES } from "./theme/theme.constants";
import AppContainer from "./routes/index";
import { Provider } from "react-redux";
import { store } from "./statemanagment/index";
import "./App.scss";

function App() {
  const [theme, setTheme] = useState(THEMES.DEFAULT);
  const updateTheme = useCallback((newTheme) => setTheme(newTheme), [setTheme]);
  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      <Provider store={store}>
        <BrowserRouter>
          <div className={`theme-${theme}`}>
            <div className={"app"}>
              <AppContainer />
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    </ThemeContext.Provider>
  );
}

export default App;
