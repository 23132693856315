import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../theme/Theme.context";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./ResultScreen.scss";
import { OptionCard, Button } from "../../components";
import { setCarModelSelection } from "../../statemanagment/index";

const ResultScreen = () => {
  const { tyreDetails } = useSelector(
    (state) => state.tyreSnapApiReducer,
    shallowEqual
  );
  const navigate = useNavigate();
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const setOption = useCallback((option) => {
    setSelectedOption(option);
  }, []);

  useEffect(() => {
    if (Object.keys(tyreDetails).length === 0) {
      navigate(`/${theme}/homeScreen`, { replace: true });
    }
  }, [tyreDetails, theme, navigate]);

  const submitDetails = useCallback(() => {
    let details = tyreDetails.car_models?.find((td, index) => {
      return selectedOption === index;
    });
    dispatch(setCarModelSelection(details));
    if (!details) {
      navigate(`/${theme}/finalScreen`);
    } else if (!details.tyre_dimensions.same_front_and_rear_dimension) {
      navigate(`/${theme}/tyreOptionScreen`);
    } else if (
      details.tyre_dimensions.front.special_marking.runflat ||
      details.tyre_dimensions.rear.special_marking.runflat
    ) {
      navigate(`/${theme}/runFlatScreen`);
    } else {
      navigate(`/${theme}/finalScreen`);
    }
  }, [selectedOption, tyreDetails, dispatch, navigate, theme]);

  return (
    <div className="result-screen">
      <div className="info-container">
        <div className="info h3">Please select</div>
        <div className="info h3">
          your CR model and the associated tyre size.
        </div>
      </div>
      <div className="option-container">
        {tyreDetails?.car_models?.map(
          (
            {
              model,
              brand,
              tyre_dimensions: { same_front_and_rear_dimension, front, rear },
            },
            index
          ) => {
            return (
              <OptionCard
                key={index}
                label={`${brand} ${model}`}
                setOption={setOption}
                checked={selectedOption === index}
                option={index}
              >
                <div className="text-color-secondary h4">
                  Homologated tyre size{" "}
                </div>
                <div className="tyre-info bold h3">
                  {!same_front_and_rear_dimension ? (
                    <>
                      <div>{`Front: ${front.tyresize}`}</div>
                      <div>{`Rear: ${rear.tyresize}`}</div>
                    </>
                  ) : (
                    <div>{front.tyresize}</div>
                  )}
                </div>
              </OptionCard>
            );
          }
        )}
        <OptionCard
          label="My tyre size is not in the list"
          setOption={setOption}
          checked={selectedOption === "default"}
          option={"default"}
        />
      </div>

      <div className="submit-container">
        <Button
          buttonStyle={
            selectedOption !== null ? "secondary-button" : "default-button"
          }
          onClick={submitDetails}
          buttonText="Confirm"
          disabledButton={selectedOption !== null ? false : true}
        />
        <div className="h6 text-color-secondary bottom-text ">
          Les dimensions affichées sont les dimensions des pneus d'équipement
          d'origine, la taille de vos pneus actuels peut différer. Nous vous
          recommandons de bien vérifier les dimensions de vos pneus actuels. Les
          indices de charge et vitesse supérieurs à ceux d'origine sont
          autorisés. Sur les pneus hiver/4saisons il est également possible de
          descendre d'un indice de vitesse.
        </div>
      </div>
    </div>
  );
};

export default ResultScreen;
