/**
 * @flow
 */
import React, { useEffect } from "react";
import { Button } from "../../components/index";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../theme/Theme.context";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setRunFlatTyreSelection } from "../../statemanagment/index";
import "./runFlatScreen.scss";

const RunFlatScreen = (): React$MixedElement => {
  const { carModelSelection } = useSelector(
    (state) => state.tyreSnapApiReducer,
    shallowEqual
  );
  const carName = `${carModelSelection.brand} ${carModelSelection.model}`;
  const navigate = useNavigate();
  const { theme } = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(carModelSelection).length === 0) {
      navigate(`/${theme}/homeScreen`, { replace: true });
    }
  }, [carModelSelection, theme, navigate]);

  return (
    <div className="runflat-screen">
      <div className="info-container">
        <div>
          <div className="h2">The tyres available for your car</div>
          <div className="italian-bold">{carName}</div>
          <div className="h2">are in Runflat</div>
        </div>
        <div>
          <div>Are you looking for</div>
          <div className="italian-bold">RUNFLAT TYRES?</div>
        </div>
      </div>
      <div className="submit-buttons-container">
        <Button
          buttonStyle="secondary-button "
          onClick={() => {
            dispatch(setRunFlatTyreSelection(true));
            navigate(`/${theme}/finalScreen`);
          }}
          buttonText="Yes"
        />
        <Button
          buttonStyle="primary-button"
          onClick={() => {
            dispatch(setRunFlatTyreSelection(false));
            navigate(`/${theme}/finalScreen`);
          }}
          buttonText="No"
        />
      </div>
    </div>
  );
};

export default RunFlatScreen;
