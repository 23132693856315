/**
 * @flow
 */
import React from "react";
import "./Spinner.scss";

type props = {
  show: boolean,
};

const Spinner = ({ show }: props): React$MixedElement => {
  return (
    <div
      className={`spinner-container ${show ? "show-spinner" : "hide-spinner"}`}
    >
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
