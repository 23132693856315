import {
  GET_TYRE_DETAIL_INITIATED,
  GET_TYRE_DETAIL_SUCCEED,
  GET_TYRE_DETAIL_FAILED,
  SET_CAR_MODEL_SELECTION,
  SET_TYRE_DIMENSION_SELECTION,
  SET_RUN_FLAT_TYRE_SELECTION,
} from "../constants/tyreSnapApiConstant.js";

export const getTyreDetailInitiated = () => ({
  type: GET_TYRE_DETAIL_INITIATED,
});

export const getTyreDetailSucceed = (payload) => ({
  type: GET_TYRE_DETAIL_SUCCEED,
  payload: payload,
});

export const getTyreDetailFailed = (payload) => ({
  type: GET_TYRE_DETAIL_FAILED,
  payload: payload,
});

export const setCarModelSelection = (payload) => ({
  type: SET_CAR_MODEL_SELECTION,
  payload: payload,
});

export const setTyreDimensionSelection = (payload) => ({
  type: SET_TYRE_DIMENSION_SELECTION,
  payload: payload,
});
export const setRunFlatTyreSelection = (payload) => ({
  type: SET_RUN_FLAT_TYRE_SELECTION,
  payload: payload,
});
