import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../theme/Theme.context";
import { cameraIcon, imageGallery, homePageVector } from "../../assets";
import { Button } from "../../components/index";
import imageCompression from "browser-image-compression";
import "./HomeScreen.scss";
import { Spinner, ErrorScreen } from "../../components/index";
import { toBase64 } from "../../utils/index";

const HomeScreen = () => {
  const navigate = useNavigate();

  const { theme, updateTheme } = useTheme();
  const { themeName } = useParams();
  useEffect(() => {
    if (theme !== themeName) {
      updateTheme(themeName);
    }
  });

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const uploadImage = async (e) => {
    let file_size = e.target.files[0].size;
    let imgValue = e.target.files[0];
    const mb = file_size / 1000000;
    sessionStorage.setItem("imageSize", mb);
    sessionStorage.setItem("imageUrl", URL.createObjectURL(imgValue));
    sessionStorage.setItem("imageType", "upload");

    if (mb > 10) {
      setLoader(true);
      const options = {
        maxSizeMB: 10,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imgValue, options);

        toBase64(compressedFile)
          .then((base64EncodedImg) => {
            sessionStorage.setItem("fileName", compressedFile.name);
            sessionStorage.setItem("imageValue", base64EncodedImg);
            setLoader(false);
            navigate(`/${theme}/viewImageScreen`);
          })
          .catch((error) => {
            setLoader(false);
            console.log(error);
            setError("Image resizing failed !!");
          });
      } catch (error) {
        console.log(error);
        setLoader(false);
        setError("Image resizing failed !!");
      }
    } else {
      toBase64(imgValue)
        .then((base64EncodedImg) => {
          sessionStorage.setItem("fileName", imgValue.name);
          sessionStorage.setItem("imageValue", base64EncodedImg);
          navigate(`/${theme}/viewImageScreen`);
        })
        .catch((error) => {
          setError("Image resizing failed !!");
          console.log(error);
        });
    }
  };

  const onClick = () => {
    navigate(`/${theme}/cameraScreen`);
  };

  if (error !== "") {
    return (
      <ErrorScreen
        errorMessage={error}
        onRetry={() => {
          setError("");
        }}
      />
    );
  }
  if (loader) {
    return <Spinner show={loader} />;
  }

  return (
    <div className="home-container">
      <div className="home-screen">
        <div className="info-container">
          <div className="info h3">
            If you want the best possible results, your image should look
          </div>
          <div className="info h3">like this:</div>
        </div>
        <div className="sample-snap-container">
          <img
            className="sample-snap"
            src={homePageVector}
            alt="sample tyre snap"
          />
        </div>
        <Button
          buttonStyle="secondary-button"
          onClick={onClick}
          icon={cameraIcon}
          buttonText="Take a Photo"
        />
        <div className="text-color h3">or</div>
        <label className={"primary-button choose-from-gallery-btn"}>
          <div className="icon-button h2">
            <img className="icon-size" src={imageGallery} alt="gallery" />
            Choose from gallery
          </div>
          <input type="file" accept="image/*" onChange={uploadImage} />
        </label>
      </div>
    </div>
  );
};

export default HomeScreen;
