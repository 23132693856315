import {
  GET_TYRE_DETAIL_INITIATED,
  GET_TYRE_DETAIL_SUCCEED,
  GET_TYRE_DETAIL_FAILED,
  SET_CAR_MODEL_SELECTION,
  SET_TYRE_DIMENSION_SELECTION,
  SET_RUN_FLAT_TYRE_SELECTION,
} from "../constants/tyreSnapApiConstant.js";

const initialState = {
  getTyreDetailsInitiated: false,
  tyreDetails: {},
  getTyreDetailsError: {},
  carModelSelection: {},
  tyreDimensionSelection: {},
  runFlatTyreSelection: false,
};

const tyreSnapApiReducer = (state = initialState, action) => {
  console.log(action.type);
  switch (action.type) {
    case GET_TYRE_DETAIL_INITIATED:
      return {
        ...state,
        getTyreDetailsInitiated: true,
      };
    case GET_TYRE_DETAIL_SUCCEED:
      return {
        ...state,
        getTyreDetailsInitiated: false,
        getTyreDetailsError: {},
        tyreDetails: action.payload,
      };
    case GET_TYRE_DETAIL_FAILED:
      return {
        ...state,
        getTyreDetailsInitiated: false,
        tyreDetails: {},
        getTyreDetailsError: action.payload,
      };
    case SET_CAR_MODEL_SELECTION:
      return {
        ...state,
        carModelSelection: action.payload,
      };
    case SET_TYRE_DIMENSION_SELECTION:
      return {
        ...state,
        tyreDimensionSelection: action.payload,
      };
    case SET_RUN_FLAT_TYRE_SELECTION:
      return {
        ...state,
        runFlatTyreSelection: action.payload,
      };

    default:
      return state;
  }
};

export default tyreSnapApiReducer;
