/**
 * @flow
 */
import React from "react";
import "./Button.scss";

type props = {
  buttonStyle: string,
  onClick: () => {},
  icon?: any,
  buttonText: string,
  disabledButton?: boolean,
};

const Button = ({
  buttonStyle,
  icon,
  onClick,
  buttonText,
  disabledButton,
}: props): React$MixedElement => {
  return (
    <button
      className={buttonStyle}
      onClick={() => onClick()}
      disabled={disabledButton}
    >
      <div className="icon-button h2">
        {icon && <img className="icon-size" src={icon} alt="icon" />}
        {buttonText}
      </div>
    </button>
  );
};

export default Button;
